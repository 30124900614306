$color-base: #febd17;
$color-dark: darken($color-base, 15%);
$color-paper: #f1f1f1;
$color-font: #141823;
$color-menu-bg: #000;
$color-backdrop: #000;
$color-link-dark: #000;
$color-site-bg: #fff;
$color-logos: #d9dadc;
$color-error: #d02b00;
$color-OK: #008000;

$color-gosag: #878292;
$color-almo: #094877;
$color-allgaier: #094877;
$color-mozer: #e8473d;
$color-mogensen: #1c9dd8;
$color-eirich: #0054a6;
$color-vortex: #00b4f1;

$breakpoint-l: 600px;
$page-max-width: 1200px;

$shadow: 13px 0px 17px -7px rgba(0, 0, 0, 0.6);
$shadow-card: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);

@function toRGB($color) {
  @return "rgb(" + red($color) + ", " + green($color) + ", " + blue($color) + ")";
}

$font-family: "Open Sans", "Trebuchet MS", sans-serif;
