@import "../../vars";

form {
  display: flex;
  flex-direction: column;
}

label {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  span {
    margin-right: 0.5rem;
  }
}

[type="text"],
[type="email"] {
  font-size: 0.8rem;
  height: 2.25rem;
  line-height: 2.25rem;
  width: 100%;
  min-width: 100px;
  padding: 0 0.5rem;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.08);
  &::placeholder {
    color: $color-font;
  }
  &:focus {
    background: $color-base;
    color: $color-menu-bg;
  }
}

textarea[type="text"] {
  height: 11rem;
}

[type="submit"] {
  font-size: 0.9rem;
  font-weight: 600;
  height: 2.25rem;
  line-height: 2.25rem;
  padding: 0 1rem;
  border: none;
  border-radius: 3px;
  background: $color-base;
  color: $color-menu-bg;
  margin: 0.5rem;
  &.disabled {
    background-color: $color-dark;
    color: rgba($color-paper, 0.6);
  }
}

.invalid {
  border: 1px solid $color-error;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.dark [type="text"],
.dark [type="email"] {
  background: $color-paper;
  color: $color-font;
  border-radius: 1px;
  margin: 0 1rem;
}

.dark [type="submit"] {
  border-radius: 1px;
  margin: 0.5rem 1.5rem 2rem;
  text-transform: uppercase;
}
