@import "../../vars";

#theme {
  height: 100%;
  display: grid;
  grid-template-rows: 80px 125px auto min-content;
  grid-template-areas:
    "header"
    "title"
    "main"
    "footer";
  transition: grid-template-rows 0.5s;
}
#theme.header-expanded {
  grid-template-rows: 150px 95px auto min-content;
}

#header {
  grid-area: header;
}

#title {
  grid-area: title;
}

#main {
  grid-area: main;
  padding: 0 1rem;
  .content {
    position: relative;
    min-height: 100px;
    padding: 1.6rem 2rem;
    line-height: 170%;
  }
}

#footer {
  grid-area: footer;
}

@media (min-width: $breakpoint-l) {
  #theme {
    grid-template-rows: 14rem 284px auto min-content;
  }
  .container {
    width: 100%;
    max-width: $page-max-width;
    margin-left: auto;
    margin-right: auto;
  }
  #main {
    display: flex;
    justify-content: center;
    .container {
      background: $color-paper;
    }
  }
}
