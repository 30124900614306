@import "vars";

svg.logos {
  fill: $color-logos;
  width: 100%;
}

svg.logos {
  &.almo:hover,
  &.almo.color {
    fill: $color-almo;
  }
  &.allgaier:hover,
  &.allgaier.color {
    fill: $color-allgaier;
  }
  &.mogensen:hover,
  &.mogensen.color {
    fill: $color-mogensen;
  }
  &.mozer:hover,
  &.mozer.color {
    fill: $color-mozer;
  }
  &.gosag:hover,
  &.gosag.color {
    fill: $color-gosag;
  }
  &.eirich:hover,
  &.eirich.color {
    fill: $color-eirich;
  }
  &.vortex:hover,
  &.vortex.color {
    fill: $color-vortex;
  }
}
