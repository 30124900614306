@import "../../vars";

picture source,
img {
  display: block;
  width: 100%;
  border-radius: 4px;
  box-shadow: $shadow-card;
  margin-bottom: 1rem;
}

.card picture source,
img {
  display: inline;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
}
