@import "../../vars";

.home__logos {
  display: flex;
  flex-wrap: wrap;
  svg {
    flex-basis: calc(50% - 2rem);
    margin: 2rem 1rem;
  }
}

.home a.third {
  padding: 2rem;
  align-self: center;
}

@media (max-width: $breakpoint-l) {
  .content.home .row {
    > div,
    > a {
      order: 100;
    }
    > div:nth-child(2) {
      order: 1;
    }
  }
}
