@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=latin,latin-ext&display=swap");
@import "vars";
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color-site-bg;
  color: $color-font;
}

.no-decoration {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
}

// normalization for other browsers
[type="checkbox"],
[type="radio"] {
  padding: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  cursor: pointer;
}

address {
  font-style: normal;
}

button.icon {
  background: transparent;
  border: none;
  padding: 0;
}

a {
  color: $color-dark;
  text-decoration: none;
  outline: none !important;
}
a:hover,
a:active {
  color: $color-base;
}

a.dark {
  color: $color-link-dark;
}
a.dark:hover,
a.dark:active {
  color: $color-paper;
}

.disabled {
  cursor: not-allowed;
}

.text-center {
  text-align: center;
}

h1 {
  font-size: 2.4rem;
  font-weight: 600;
}

h2 {
  font-weight: 700;
  text-align: center;
  &::after {
    content: "\00a0 ";
    display: block;
    width: 20%;
    height: 11px;
    margin: 0.5rem auto;
    background: rgba(0, 0, 0, 0.1);
  }
}

.dark h2 {
  color: $color-base;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.1rem;
  &::after {
    display: none;
  }
}

svg.logos {
  fill: $color-logos;
}

html {
  scrollbar-color: $color-base $color-site-bg;
  scrollbar-width: thin;
  overflow-y: scroll;
}
html::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid $color-site-bg;
}

html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $color-dark;
  border: 1px solid $color-site-bg;
}

html::-webkit-scrollbar-thumb:hover {
  background: $color-base;
}

html::-webkit-scrollbar-thumb:active {
  background: $color-base;
}
html::-webkit-scrollbar {
  width: 0.4em;
  background-color: $color-site-bg;
}
