@import "./vars";

.base-bg {
  display: flex;
  background: $color-base;
  margin: 1.6rem 0;
  font-size: 1.1rem;
  justify-content: space-between;
  > div {
    width: 100%;
    margin-right: 2rem;
  }
  > div:last-child {
    margin-right: 0;
  }
  h2::after {
    display: none;
  }
}

div.dark {
  background: $color-menu-bg;
  color: $color-paper;
  border-radius: 2px;
}

.row {
  display: flex;
  justify-content: space-evenly;
}
.flex-wrap {
  flex-wrap: wrap;
}
.full {
  flex-basis: 100%;
}
.half {
  flex-basis: 50%;
}
.third {
  flex-basis: 33%;
}
.third-2 {
  flex-basis: 66%;
}

.card {
  background: $color-site-bg;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: $shadow-card;
  margin: 1rem;
  height: calc(100% - 2rem);
  .card-img {
    width: 100%;
    min-height: 160px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .logos {
      margin: 5% 10%;
    }
  }
  .card-content {
    padding: 0 2rem 1rem;
    font-size: 0.9rem;
  }
  h3 {
    font-weight: 600;
    font-size: 1.3rem;
  }
  blockquote {
    font-weight: 600;
    text-decoration: solid;
    text-align: center;
    margin: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      text-align: left;
      padding: 0 0 0 1rem;
      &::before {
        content: "\2022";
        color: $color-base;
        font-weight: bold;
        display: inline-block;
        position: absolute;
        margin-left: -1rem;
      }
    }
  }
}

.content {
  img {
    width: 100%;
  }
  img.content-image,
  .content-image source,
  .content-image img {
    border-radius: 4px;
    box-shadow: $shadow-card;
    margin: 1rem 0;
  }
  ul.row {
    justify-content: flex-start;
  }
  li.half {
    min-width: 50%;
    flex-basis: auto;
  }
}

.padding-text-left {
  padding: 0 2rem 1rem 0;
}
.padding-text-right {
  padding: 0 0 1rem 2rem;
}

.padding-top {
  padding-top: 2rem;
}
.padding-bottom {
  padding-bottom: 2rem;
}

.padding-container {
  padding: 2rem;
}

#main .icon svg {
  width: 30px;
  max-width: 30px;
  padding: 0 1rem 0 0;
  height: 30px;
  vertical-align: middle;
}

.aside {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  max-width: 500px;
  margin: 2rem 0 2rem auto;
  height: calc(100% - 4rem);
  padding: 8%;
  border-radius: 2px;
}

@media (max-width: $breakpoint-l) {
  #main .content.base-bg {
    padding: 1.2rem;
  }
  .base-bg {
    flex-wrap: wrap;
    > div {
      margin-right: 0;
    }
  }
  .third-2,
  .third,
  .half,
  .third,
  .card-container.almo {
    flex-basis: 100%;
  }
  .base-bg li.half {
    flex-basis: 50%;
  }
  .card {
    margin: 1rem 0;
    .card-img {
      height: 140px;
    }
  }
  #main .content {
    padding: 0;
  }
  .row {
    flex-direction: column;
  }
  .aside {
    margin: 0;
    padding: 0;
  }
  .padding-container,
  .padding-text-left,
  .padding-text-right {
    padding: 0.2rem;
  }
}
